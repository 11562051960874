import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiListingResponse } from '@app/shared/models';
import { PartnerModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getPartners(): Observable<PartnerModel[]> {
        const params = new HttpParams().appendAll({
            first: 1,
            limit: 0,
        });
        return this.httpClient.get<ApiListingResponse<PartnerModel[]>>('partners', { params }).pipe(map((response) => response.data));
    }
}
