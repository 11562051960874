import { Injectable } from '@angular/core';
import { of, catchError, map, mergeMap, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpService } from '../services/http.service';
import { partnersActions } from '../actions/partners.actions';
import { GuiFacade } from '@app/gui';
import { UserFacade } from '@app/modules';
import { Roles } from '@app/modules/permissions';

@Injectable()
export class PartnersEffects {
    getPartnersAll$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(partnersActions.getPartnersAction),
            tap(() => this.guiFacade.showLoader('get-partners')),
            mergeMap(() => {
                if (this.userFacade.state.user?.role === Roles.ROLE_PARTNER_EMPLOYEE) {
                    return of([this.userFacade.state.user?.partner]).pipe(
                        map((data) => partnersActions.getPartnersSuccessAction({ payload: data })),
                        catchError(() => of(partnersActions.getPartnersErrorAction())),
                    );
                }

                return this.httpService.getPartners().pipe(
                    map((data) => partnersActions.getPartnersSuccessAction({ payload: data })),
                    catchError(() => of(partnersActions.getPartnersErrorAction())),
                );
            }),
        );
    });

    getPartnersAlways$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(partnersActions.getPartnersSuccessAction, partnersActions.getPartnersErrorAction),
                tap(() => this.guiFacade.hideLoader('get-partners')),
            );
        },
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private guiFacade: GuiFacade,
        private httpService: HttpService,
        private userFacade: UserFacade,
    ) {}
}
