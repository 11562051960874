import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PARTNERS_TEMP_FEATURE_KEY } from './keys';
import { partnersReducer } from './reducers/partners.reducer';
import { PartnersEffects } from './effects/partners.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(PARTNERS_TEMP_FEATURE_KEY, partnersReducer), EffectsModule.forFeature([PartnersEffects])],
})
export class PartnersStoreTempModule {}
